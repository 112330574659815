



































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Inject } from "vue-property-decorator";
import { TrainingGroundEventViewmodel } from "../viewmodels/training-ground-event-viewmodel";

@Observer
@Component({
  components: {
    CharacterAvatar: () => import("@/components/character/character-avatar.vue"),
  },
})
export default class IncreaseUnlockTime extends Vue {
  @Inject() vmEvent!: TrainingGroundEventViewmodel;
}
